<template>
    <div style="width:100%;height:100%">
        <v-navigation-drawer absolute permanent :touchless="true" :expand-on-hover="activeLayer === 'routePlanner' ? false: true"
            :width="activeLayer === 'routePlanner' ? '540': ''">
            <v-list nav dense>
                <v-list-item @click="setBounds();initPanel(null)" link>
                    <v-list-item-icon>
                        <v-icon>zoom_out_map</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Zoom to fleet</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item v-if="features && features.layers && features.layers.windRainGfs === 1"
                    @click="activeLayer === 'wind' ? activeLayer = null : activeLayer = 'wind';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'wind' ? 'primary': ''">mdi-weather-windy-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Wind, MSLP & precipitation</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="features && features.layers && features.layers.globalWave === 1"
                    @click="activeLayer === 'wave' ? activeLayer = null : activeLayer = 'wave';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'wave' ? 'primary': ''">waves</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Significant wave</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="features && features.layers && features.layers.globalOceanCurrents === 1"
                    @click="activeLayer === 'current' ? activeLayer = null : activeLayer = 'current';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'current' ? 'primary': ''">trending_down</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Global ocean currents</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="features && features.layers && features.layers.windAnimation === 1"
                    @click="activeLayer === 'windAnimation' ? activeLayer = null : activeLayer = 'windAnimation';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'windAnimation' ? 'primary': ''">mdi-weather-windy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Animated GFS wind</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="features && features.layers && features.layers.balticIce === 1"
                    @click="activeLayer === 'ice' ? activeLayer = null : activeLayer = 'ice';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'ice' ? 'primary': ''">layers</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>BALTICE data</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="features && features.layers && features.layers.elevation === 1"
                    @click="activeLayer === 'elevation' ? activeLayer = null : activeLayer = 'elevation';toggleWindAnimationLayer()" link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'elevation' ? 'primary': ''">mdi-elevation-decline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Elevation</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item v-if="features && features.layers && features.layers.eventHistory === 1"
                    @click="activeLayer === 'eventHistory' ? activeLayer = null : activeLayer = 'eventHistory';toggleEventHistory(true);toggleWindAnimationLayer()"
                    link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'eventHistory' ? 'primary': ''">mdi-history</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Event history</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="activeLayer === 'routePlanner' ? activeLayer = null : activeLayer = 'routePlanner';initRoutePlanner();toggleWindAnimationLayer()"
                    link>
                    <v-list-item-icon>
                        <v-icon :color="activeLayer === 'routePlanner' ? 'primary': ''">mdi-routes</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Plan a route</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="activeLayer === 'routePlanner'">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="5" class="py-0">
                                <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="routePlanner.startDate"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field @change="calculateRouteTimes(true)" v-model="routePlanner.startDate" label="Start date">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="routePlanner.startDate"
                                        @change="$refs.sdmenu.save(routePlanner.startDate);calculateRouteTimes(true)" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.sdmenu.save(routePlanner.startDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="5" class="py-0">
                                <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="routePlanner.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="routePlanner.startTime" @change="calculateRouteTimes(true)" label="Start time">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">access_time</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="startTimeMenu" v-model="routePlanner.startTime" @change="calculateRouteTimes(true)" format="24hr"
                                         @click:minute="$refs.stmenu.save(routePlanner.startTime)"></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="5" class="py-0">
                                <v-text-field v-model="speed" prepend-icon="speed" @change="calculateRouteTimes(true)" step="0.01" min="1" type="number"
                                    label="Speed"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" class="py-0">
                                <v-select v-model="portHours" :items="hours" @change="calculateRouteTimes(true)" prepend-icon="timelapse" label="Port hours" />
                            </v-col>
                            <v-col cols="12" sm="10" class="pt-0">
                                <v-autocomplete prepend-icon="mdi-anchor" @input="addPort()" v-model="newPort" :loading="portLoading" :items="ports"
                                    :item-text="portItemText" item-key="id" :clearable="true" :search-input.sync="portSearch" cache-items return-object text
                                    hide-no-data hide-details label="Add port">
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" sm="2" class="pt-0">
                                <v-btn class="mt-3" color="primary" :disabled="!newPort || Object.entries(newPort).length === 0" @click="addPort()"
                                    v-on:keyup.enter="addPort()">
                                    Add
                                </v-btn>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <ejs-grid ref="grid" :dataSource="routePlanner.ports" :height="120" :allowRowDragAndDrop="true" :queryCellInfo="customizeCell"
                                    :rowSelected="rowSelected" :rowDeselected="rowDeselected" :rowDrop="dropEnd">
                                    <e-columns>
                                        <e-column field="name" headerText="Port" :isPrimaryKey="true" width="120"></e-column>
                                        <e-column field="eta" headerText="ETA" width="75" format="d.M HH:mm" type="date"></e-column>
                                        <e-column field="etd" headerText="ETD" width="75" format="d.M HH:mm" type="date"></e-column>
                                        <e-column field="distance" headerText="NM" width="70"></e-column>
                                        <e-column field="days" headerText="Days" width="70"></e-column>
                                    </e-columns>
                                    <e-aggregates>
                                        <e-aggregate>
                                            <e-columns>
                                                <e-column type="Sum" field="distance" :footerTemplate="footerDistanceTotal" headerTextAlign="Center"></e-column>
                                                <e-column type="Custom" :customAggregate="customSumAggregateFn" field="days" :footerTemplate="footerDaysTotal"
                                                    headerTextAlign="Center"></e-column>
                                            </e-columns>
                                        </e-aggregate>
                                    </e-aggregates>
                                </ejs-grid>

                                <v-btn class="mt-3" color="error"
                                    :disabled="typeof routePlanner.ports === 'undefined' || routePlanner.ports.length === 0 || selectedRowIndex === null"
                                    @click="deletePort()">
                                    Remove port
                                </v-btn>

                                <v-btn class="mt-3" style="float:right" color="error"
                                    :disabled="typeof routePlanner.ports === 'undefined' || routePlanner.ports.length === 0" @click="initRoutePlanner()">
                                    Clear
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-list-item>
                <v-divider />
                <v-list-item
                    @click="toggle('showRoutes')" link>
                    <v-list-item-icon>
                        <v-icon :color="showRoutes ? 'primary': ''">mdi-chart-timeline-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Show active route</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="toggle('showCircles')" link>
                    <v-list-item-icon>
                        <v-icon :color="showCircles ? 'primary': ''">mdi-circle-double</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Show range rings</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer absolute v-if="showPanel" permanent :touchless="true" right width="430">
            <v-row class="fill-height" no-gutters>
                <v-navigation-drawer mini-variant mini-variant-width="56" style="position:fixed" permanent>
                    <v-list dense nav>
                        <v-list-item v-for="(item, index) in panelsFiltered" :key="index" @click="switchPanel(item.title)" :title="item.title" link>
                            <v-list-item-action>
                                <v-icon v-if="item.title === 'Norppa'" :color="activePanel === item.title ? 'primary': ''" class="schedule-icon norppa"></v-icon>
                                <v-icon v-else :color="activePanel === item.title ? 'primary': ''">{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>

                <v-card width="370" style="margin-left:56px">
                    <v-card-title class="pb-0">{{ ship.name }}</v-card-title>
                    <div v-if="activePanel === 'General'">
                        <v-row no-gutters>
                            <v-col xs="7">
                                <v-card-subtitle class="pb-0">AIS destination</v-card-subtitle>
                                <v-card-text class="pb-0">{{ ship.destination }}</v-card-text>
                            </v-col>
                            <v-col xs="5">
                                <v-card-subtitle class="pb-0">AIS ETA (UTC)</v-card-subtitle>
                                <v-card-text class="pb-0">{{ formatTime(ship.eta) }}</v-card-text>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col xs="7">
                                <v-card-subtitle class="pb-0">AIS status</v-card-subtitle>
                                <v-card-text class="pb-0">
                                    <span :class="getNavstat(ship.navstat).color + '--text'">{{ getNavstat(ship.navstat).status }}</span>
                                </v-card-text>
                            </v-col>
                            <v-col xs="5">
                                <v-card-subtitle class="pb-0">AIS pos. updated</v-card-subtitle>
                                <v-card-text class="pb-0"><span :class="timeAgoColor(ship.time) + '--text'">{{ timeAgo(ship.time) }}</span> <span v-show="ship.source">({{ ship.source }})</span></v-card-text>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4" />
                        <v-row no-gutters style="text-align: center !important">
                            <v-col xs="4">
                                <v-card-subtitle class="pb-1">COG</v-card-subtitle>
                                <v-card-text class="pb-0 xlarge">{{ ship.cog }} {{ unit(ship.cog, '°') }}</v-card-text>
                            </v-col>
                            <v-col xs="4">
                                <v-card-subtitle class="pb-1">
                                    <span class="arrow_sog" :style="sogColor(ship.sog)" style="border-bottom: 15px solid rgb(68, 108, 179);"></span>
                                </v-card-subtitle>
                                <v-card-text class="pb-0"><span class="xlarge">{{ round(ship.sog, 1) }}</span> {{ unit(ship.sog, 'kn') }}</v-card-text>
                            </v-col>
                            <v-col xs="4">
                                <v-card-subtitle class="pb-1">Draught</v-card-subtitle>
                                <v-card-text class="pb-0"><span class="xlarge">{{ round(ship.draught, 1) }}</span> {{ unit(ship.draught, 'm') }}</v-card-text>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4" />
                        <div v-if="shipWeather">
                            <v-row no-gutters>
                                <v-col xs="4">
                                    <v-card-subtitle class="pb-1">Wind</v-card-subtitle>
                                    <v-card-text v-if="shipWeather.tws" :title="'Forecasted wind at ' + formatTime(shipWeather.forecastTime) + ' UTC'" class="pb-0" style="color:#19B5FE">
                                        <span class="xlarge">{{ round(shipWeather.tws, 1) }}</span>
                                        <span class="large">/{{ round(shipWeather.gust, 1) }}</span>m/s<br /><span class="large">{{ compass(shipWeather.twd) }}
                                            {{ shipWeather.twd }}°</span>
                                    </v-card-text>
                                    <v-card-text v-if="shipWeather.shipTws || shipWeather.shipTwd" :title="'Ship weather station wind at ' + formatTime(shipWeather.shipTime) + ' UTC'" class="pb-0 mt-n3" style="color:#9b59b6">
                                        <span v-show="shipWeather.shipTws"><span class="xlarge">{{ round(shipWeather.shipTws, 1) }}</span>m/s<br /></span>
                                        <span v-show="shipWeather.shipTwd" class="large">{{ compass(shipWeather.shipTwd) }} {{ round(shipWeather.shipTwd, 0) }}°</span>
                                    </v-card-text>
                                    <v-card-text v-if="!shipWeather.tws && !shipWeather.shipTws" class="pb-0">N/A</v-card-text>
                                    <v-card-subtitle class="pb-1">Wave</v-card-subtitle>
                                    <v-card-text v-if="shipWeather.swh" class="pb-0" style="color:#E26A6A">
                                        <span class="xlarge">{{ round(shipWeather.swh, 1) }}</span>m<br /><span
                                            class="large">{{ compass(shipWeather.pwd) }} {{ round(shipWeather.pwd, 0) }}°</span>
                                    </v-card-text>
                                    <v-card-text v-else class="pb-0">N/A</v-card-text>
                                    <v-card-subtitle class="pb-1">Current</v-card-subtitle>
                                    <v-card-text v-if="shipWeather.cspd" class="pb-0" style="color:#2ecc71">
                                    <span class="xlarge">{{ round(shipWeather.cspd, 1) }}</span>m/s<br /><span
                                            class="large">{{ compass(shipWeather.cdir) }} {{ round(shipWeather.cdir, 0) }}°</span>
                                    </v-card-text>
                                    <v-card-text v-else class="pb-0">N/A</v-card-text>
                                </v-col>
                                <v-col xs="8" style="position:relative">
                                    <WeatherCompass :sog="ship.sog" :cog="ship.cog" :heading="ship.heading" :swellDir="shipWeather.pwd" :windDir="shipWeather.twd" :currentDir="shipWeather.cdir" :shipTwd="shipWeather.shipTwd" :mmsi="ship.mmsi" :navstat="ship.navstat" />
                                </v-col>
                            </v-row>
                            <v-divider class="mt-4" />
                        </div>
                        <div v-if="streams">
                            <span v-for="(cam, index) in streams" :key="index">
                                <v-card>
                                    <v-card-title style="font-size:18px"> {{ cam.name }} </v-card-title>
                                    <v-card-subtitle class="pb-1"> {{ cam.description }} </v-card-subtitle>
                                    <div class="ml-4" :id="'sldp_player_wrapper' + cam.id"></div>
                                    <v-card-actions>
                                        <v-icon v-if="!cam.button" @click="getStreamUrl(index, ship.id)" class="mx-2" color="success"
                                            :title="'Start streaming \'' + cam.name + '\''">mdi-play-circle-outline
                                        </v-icon>
                                        <v-icon v-else @click="endStream(index)" class="mx-2" color="error" :title="'End streaming \'' + cam.name + '\''">
                                            mdi-stop-circle-outline
                                        </v-icon>

                                        <v-menu v-if="cam.controls && cam.controls.length > 0" offset-y>
                                            <template #activator="{ on, attrs }">
                                                <v-icon class="ml-n1 mr-1" :color="cam.vIconColor" :title="'Control commands'" v-bind="attrs" v-on="on">
                                                    mdi-cog-outline
                                                </v-icon>
                                            </template>
                                            <v-list dense>
                                                <v-list-item v-if="cam.button" @click="endStream(index)">
                                                    <v-list-item-icon class="mr-2">
                                                        <v-icon color="error">mdi-stop-circle-outline</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Stop streaming</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-else @click="getStreamUrl(index, ship.id)">
                                                    <v-list-item-icon class="mr-2">
                                                        <v-icon color="success">mdi-play-circle-outline</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Start streaming</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-for="control in cam.controls" :key="control.id" @click="cameraCommand(cam, ship.id, control)">
                                                    <v-list-item-icon class="mr-2">
                                                        <v-icon v-text="control.icon"></v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{ control.name }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <v-menu open-on-hover top offset-y class="mx-2">
                                            <template #activator="{ on }">
                                                <v-icon v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                            <v-list style="padding:10px;">
                                                {{ cam.description }}
                                            </v-list>
                                        </v-menu> -->
                                        <v-spacer />
                                        <v-btn v-show="cam.url && cam.button" text class="accent--text" @click="endStream(index)">{{ cam.button }}</v-btn>
                                    </v-card-actions>
                                    <v-divider class="mt-2" />
                                </v-card>
                            </span>
                        </div>
                        <v-card-text class="pb-0" style="text-align: center; font-size:12px">
                            IMO {{ ship.imo }} - {{ ship.callSign }}{{ ship.dwt ? ' - DWCC ' + ship.dwt : '' }}{{ ship.cuf ? ', ' + ship.cuf + ' CBFT' : '' }}
                        </v-card-text>
                        <v-divider class="mt-4" />
                        <div class="chart" ref="chartdiv"></div>
                    </div>
                    <div v-if="activePanel === 'Maranics'">
                        <v-container>
                            <v-row>
                                <v-col xs="10">
                                    <v-text-field v-model="search" style="width:260px; margin-right:12px" title="Filter out checklist items" :clearable="true"
                                        prepend-icon="mdi-magnify" label="Search" single-line hide-details />
                                </v-col>
                                <v-col xs="2">
                                    <v-dialog v-model="dialog" scrollable max-width="450px">
                                        <template #activator="{ on, attrs }">
                                            <v-icon style="margin-top:18px" v-bind="attrs" v-on="on" @click="getItemTitles()">mdi-filter-variant</v-icon>
                                        </template>
                                        <v-card>
                                            <v-card-title>Select hidden items</v-card-title>
                                            <v-card-subtitle class="mt-n2">Selected items are hidden for all users and ships</v-card-subtitle>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 350px;margin-top:16px">
                                                <v-checkbox v-for="(item, index) in checklistTitles" :key="index" v-model="checklistItems"
                                                    :label="item.itemTitle" :value="item.itemTitle" :disabled="userLevel < 2" class="py-0 my-0"></v-checkbox>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-btn color="error" text @click="dialog = false">
                                                    Close
                                                </v-btn>
                                                <v-spacer />
                                                <v-btn color="primary" text :disabled="userLevel < 2" @click="saveHidden()">
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-card-title v-if="checklists.length === 0" style="font-size:16px">No checklist data</v-card-title>
                        <div v-else>
                            <v-divider />
                            <v-list-item two-line v-for="(item, index) in checklistsFiltered" :key="index" style="min-height:60px" @click="showEventMarker(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div :title="item.flowTitle + ' - ' + item.itemTitle"
                                            style="display:inline-block;width: 245px !important;overflow-x: hidden;text-overflow: ellipsis;">
                                            {{ item.itemTitle }}
                                        </div> <span :title="formatTime(item.time) + ' UTC'"
                                            style="position: absolute; right:10px; font-size:13px; line-height:20px"
                                            :class="timeAgoColor(item.time) + '--text'">{{ timeAgo(item.time) }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div :title="item.itemSecondaryTitle"
                                            style="display:inline-block;width: 245px !important;overflow-x: hidden;text-overflow: ellipsis;">
                                            {{ item.itemSecondaryTitle }}
                                        </div> <span
                                            style="position: absolute; right:10px; font-size:13px; line-height:20px">{{ item.updatedByPosition }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </div>
                    <div v-if="activePanel === 'Logbook'">
                        <div class="mt-2">
                            <v-list-item two-line v-for="(item, index) in records" :key="index" style="min-height:60px" @click="showEventMarker(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div :title="item.name" :style="{ 'text-decoration': item.status === -10 ? 'line-through' : '' }"
                                            style="display:inline-block;width: 245px !important;overflow-x: hidden;text-overflow: ellipsis;">
                                            {{ item.name }}
                                        </div> <span :title="formatTime(item.eventTime) + ' UTC'" :style="{ 'text-decoration': item.status === -10 ? 'line-through' : '' }"
                                            style="position: absolute; right:10px; font-size:13px; line-height:20px"
                                            :class="timeAgoColor(item.eventTime) + '--text'">{{ timeAgo(item.eventTime) }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div :title="item.summary" :style="{ 'text-decoration': item.status === -10 ? 'line-through' : '' }"
                                            style="display:inline-block;width: 245px !important;overflow-x: hidden;text-overflow: ellipsis;">
                                            {{ item.summary }}
                                        </div> <span :style="{ 'text-decoration': item.status === -10 ? 'line-through' : '' }"
                                            style="position: absolute; right:10px; font-size:13px; line-height:20px">{{ item.statusText }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </div>
                    <div v-if="activePanel === 'Norppa'">
                        <v-container class="pa-0 mt-2">
                            <v-alert v-if="schedule && schedule[0] && schedule[0].header_lastupdated" dense outlined icon="mdi-alert-circle-outline" color="primary">
                                Itinerary updated by Operator {{ fromNow(schedule[0].header_lastupdated) }}
                            </v-alert>
                            <v-alert v-else class="mb-2" icon="mdi-alert-circle-outline" outlined dense color="red"
                                style="background-color:#fff!important">
                                No schedule data available.
                            </v-alert>
                            <v-expansion-panels v-model="panel">
                                <v-expansion-panel v-for="(item, i) in schedule" :key="i" :disabled="item.Operation === 'W'" style="margin-top:3px">
                                    <v-expansion-panel-header class="px-4 py-2" style="height:60px" :id="'scrollTo'+i"
                                        :style="'border-left:4px solid ' + operationColor(item)">
                                        <v-row>
                                            <v-col cols="2" xs="2">
                                                <v-icon v-show="item.Operation === 'W'" class="schedule-icon anchor" large></v-icon>
                                                <v-icon v-show="item.Operation === 'L' && inPast(item)" class="schedule-icon loading-past"
                                                    large>
                                                </v-icon>
                                                <v-icon v-show="item.Operation === 'D' && inPast(item)"
                                                    class="schedule-icon discharging-past" large></v-icon>
                                                <v-icon v-show="item.Operation === 'L' && !inPast(item)" class="schedule-icon loading"
                                                    large>
                                                </v-icon>
                                                <v-icon v-show="item.Operation === 'D' && !inPast(item)" class="schedule-icon discharging"
                                                    large>
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="10" class="pl-0" :style="inPast(item) ? 'opacity:0.7' : ''">
                                                <h3>
                                                    <span>{{ item.Port }} </span>
                                                    <span style="font-size:12px;font-weight:normal" v-show="item.Voyage_Number">voy {{ item.Voyage_Number }}</span>
                                                </h3>
                                                <div class="mt-2" style="white-space:nowrap">
                                                    {{ showSubtitle(item, i) }}
                                                </div>
                                            </v-col>
                                            <div class="hide-active"
                                                style="position:absolute;right:10px;top:10px;text-transform:uppercase;font-size:11px"
                                                :style="'color:' + statusColor(item)">
                                            {{ item.Status }}
                                            </div>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div v-if="item.Voyage_Number">
                                            <PortDetails :item="item" :showDetails="showDetails" :secure="item" :showTimes="true" />

                                            <div v-if="showDetails" @click="showDetails = false" class="show-details">
                                                Hide details<v-icon style="color:#003C96;margin-bottom:1px;">mdi-chevron-up</v-icon>
                                            </div>
                                            <div v-else @click="showDetails = true" class="show-details">
                                                Show confidential details<v-icon style="color:#003C96;margin-bottom:1px;transform:rotate(90deg)">
                                                    mdi-chevron-up
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-container>
                    </div>
                </v-card>
            </v-row>
        </v-navigation-drawer>

        <v-map ref="map" :key="mapKey" style="left:56px; width:calc(100% - 55px); height: 100%;" :style="mapSize()" :zoom="zoom" :center="center"
            :bounds="bounds" @update:zoom="updateZoom($event)">
            <l-control-layers />
            <div :key="baseLayerKey">
                <v-tilelayer v-for="(layer, index) in baseLayers" :key="index" :name="layer.name" :url="layer.url" :attribution="layer.attribution"
                    :options="{tileSize: layer.tileSize, crossOrigin: layer.crossOrigin, zoomOffset: -1, maxZoom: 20}" :visible="layer.visible" layer-type="base">
                </v-tilelayer>
            </div>
            <div v-if="activeLayer === 'wave'">
                <l-wms-tile-layer v-for="time in waveOptions.data" :key="'wave_'+time" :base-url="baseUrl" layers="Global_wave_forecast"
                    :visible="time === waveValue" name="Global wave" :opacity="0.9" :zIndex="500" :transparent="true" format="image/png"
                    :options="{time: time}" />
            </div>
            <div v-if="activeLayer === 'wind'">
                <l-wms-tile-layer v-for="time in windOptions.data" :key="'wind_'+time" :base-url="baseUrl" layers="wind_rain_gfs"
                    :visible="time === windValue" name="Wind, MSLP, precipitation" :opacity="0.7" :zIndex="500" format="image/png" :transparent="true"
                    :options="{time: time}" />
            </div>
            <div v-if="activeLayer === 'current'">
                <l-wms-tile-layer v-for="time in currentOptions.data" :key="'current_'+time" :base-url="baseUrl" layers="global_combined_currents"
                    :visible="time === currentValue" name="Global ocean currents" :opacity="0.9" :zIndex="500" format="image/png" :transparent="true"
                    :options="{time: time}" />
            </div>
            <div v-if="activeLayer === 'ice'">
                <l-wms-tile-layer base-url="https://baltice.org/wms" layers="fmi:ice:icechart_iceareas" version="1.3.0" name="Baltic ice" :opacity="0.7" :zIndex="500" format="image/png" :transparent="true" />
                <v-geojson :geojson="waypointsGeojson"></v-geojson>
                <span v-if="winterShips && winterShips.length > 0">
                    <span v-for="s in winterShips" :key="s.shipname">
                        <v-rotated-marker :lat-lng="s.latLng" :icon="s.icon" :rotationAngle="s.course">
                            <l-tooltip :content="s.tooltip" :options="s.options" />
                        </v-rotated-marker>
                    </span>
                </span>
            </div>
            <div v-if="activeLayer === 'elevation'">
                <l-wms-tile-layer :base-url="baseUrl" layers="elevation" name="Elevation" :opacity="0.7" :zIndex="500" format="image/png" :transparent="true" />
            </div>
            <div v-if="features && features.layers && features.layers.common === 1">
                <l-wms-tile-layer v-for="layer in layers" :key="layer.name+baseLayerKey" :base-url="layer.url" :layers="layer.layers" :visible="layer.visible"
                    :name="layer.name" :opacity="layer.opacity" :zIndex="layer.zIndex" :format="layer.format" :attribution="layer.attribution"
                    :transparent="true" layer-type="overlay" />
            </div>
            <div v-if="features && features.layers && features.layers.USLayers === 1">
                <l-wms-tile-layer v-for="layer in USLayers" :key="layer.name+baseLayerKey" :base-url="layer.url" :layers="layer.layers" :visible="layer.visible"
                    :name="layer.name" :opacity="layer.opacity" :zIndex="layer.zIndex" :format="layer.format" :attribution="layer.attribution"
                    :transparent="true" layer-type="overlay" />
            </div>
            <v-tilelayer v-for="(layer, index) in tileLayers" :key="index+tileLayerKey" :name="layer.name" :url="layer.url" :attribution="layer.attribution"
                :zIndex="layer.zIndex" :visible="layer.visible" layer-type="overlay"></v-tilelayer>
            <span v-if="ships && ships.length > 0 && (!events || events.length === 0)">
                <span v-for="s in ships" :key="s.id">
                    <span v-if="showRoutes || (ship && s.id === ship.id)">
                        <v-geojson v-if="s.routeGeojson" :geojson="s.routeGeojson" :options="geojsonLabel" :options-style="route.options"></v-geojson>
                        <v-geojson v-if="s.corridorGeojson" :geojson="s.corridorGeojson" :options="geojsonLabel" :options-style="corridorStyleFunction">
                        </v-geojson>
                    </span>
                    <v-geojson v-if="pastTracks[s.id]" :geojson="pastTracks[s.id]" :options="pastTrackOptions"></v-geojson>
                    <v-geojson v-if="s.shipGeojson" :geojson="s.shipGeojson" :options="shipGeojsonOptions"></v-geojson>
                    <span v-if="s.circles && showCircles">
                        <l-circle v-for="(circle, index) in s.circles" :key="random()+index" :lat-lng="circle.center" :radius="circle.radius"
                            :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                    </span>
                    <v-rotated-marker v-if="s.predictor" :lat-lng="s.predictor.latLng" :icon="s.predictor.icon"
                        :rotationAngle="s.predictor.direction" :rotationOrigin="s.predictor.rotationOrigin">
                    </v-rotated-marker>
                    <v-rotated-marker v-if="s.latLng" @click="initPanel(s)" :lat-lng="s.latLng" :icon="s.icon" :rotationAngle="s.heading">
                        <l-tooltip :content="s.tooltip" :options="s.tooltipOptions" />
                        <!-- <l-popup :content="s.popup"></l-popup> -->
                    </v-rotated-marker>
                </span>
            </span>
            <span v-if="aisTargets && aisTargets.length > 0">
                <span v-for="(target, index) in aisTargets" :key="index">
                    <v-rotated-marker v-if="target.predictor" :lat-lng="target.predictor.latLng" :icon="target.predictor.icon"
                        :rotationAngle="target.predictor.direction" :rotationOrigin="target.predictor.rotationOrigin">
                    </v-rotated-marker>
                    <v-rotated-marker v-if="target.latLng" :lat-lng="target.latLng" :icon="target.icon" :rotationAngle="target.heading">
                        <l-tooltip :content="target.tooltip" :options="target.tooltipOptions" />
                        <l-popup :content="target.popup"></l-popup>
                    </v-rotated-marker>
                </span>
            </span>
            <span v-if="events && events.length > 0">
                <span v-for="event in events" :key="event.id">
                    <v-rotated-marker v-if="event.latLng" :lat-lng="event.latLng" :icon="event.icon" :rotationAngle="event.heading">
                        <l-tooltip :content="event.name + ' - ' + event.eventShort" :options="event.tooltipOptions" />
                        <l-popup :content="event.popup" :options="event.popupOptions"></l-popup>
                    </v-rotated-marker>
                </span>
            </span>

            <span v-if="typeof routePlanner.routes !== 'undefined' && routePlanner.routes.length > 0">
                <v-geojson v-for="(route, index) in routePlanner.routes" :key="index" :geojson="route" :options="route.options"></v-geojson>
            </span>

            <span v-if="triggers && triggers.length > 0">
                <span v-for="trigger in triggers" :key="trigger.id">
                    <v-geojson v-if="trigger.content && trigger.content.geojson" :options-style="triggerStyle"
                        :geojson="trigger.content.geojson" :options="triggerGeoOptions">
                    </v-geojson>
                </span>
            </span>

            <l-marker v-if="Object.keys(eventMarker).length !== 0" :lat-lng="eventMarker.latLng">
                <l-tooltip :options="{ permanent: true, interactive: true }">
                    <div>
                        {{ eventMarker.title }}
                    </div>
                </l-tooltip>
            </l-marker>
        </v-map>
        <vue-slider v-if="activeLayer === 'wave'" id="waveSlider" class="slider" ref="waveSlider" v-model="waveValue" v-bind="waveOptions"
            :tooltip-formatter="waveOptions.formatter"></vue-slider>
        <vue-slider v-if="activeLayer === 'wind'" id="windSlider" class="slider" ref="windSlider" v-model="windValue" v-bind="windOptions"
            :tooltip-formatter="windOptions.formatter"></vue-slider>
        <vue-slider v-if="activeLayer === 'current'" id="currentSlider" class="slider" ref="currentSlider" v-model="currentValue" v-bind="currentOptions"
            :tooltip-formatter="currentOptions.formatter">
        </vue-slider>
        <vue-slider v-if="activeLayer === 'eventHistory'" id="eventSlider" class="slider" ref="eventSlider" v-model="eventValue" v-bind="eventOptions"
            :tooltip-formatter="eventOptions.formatter" @drag-start="() => inDragging = true"
            @drag-end="() => { inDragging = false; toggleEventHistory(false); }" @callback="toggleEventHistory(false)" :dragOnClick="true" :clickable="true">
        </vue-slider>
        <vue-slider v-if="activeLayer === 'windAnimation'" id="windAnimationSlider" class="slider" ref="windAnimationSlider" v-model="windAnimationValue"
            :tooltip-formatter="windAnimationOptions.formatter" @drag-start="() => inDragging = true"
            @drag-end="() => { inDragging = false; toggleWindAnimationLayer(); }" @callback="toggleWindAnimationLayer()" :dragOnClick="true" :clickable="true"
            v-bind="windAnimationOptions">
        </vue-slider>
        <!-- Components -->
        <StatusDialog />
    </div>
</template>

<script>
    import L from 'leaflet';
    import 'leaflet-mouse-position';
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import {
        LMap,
        LTileLayer,
        LWMSTileLayer,
        LControlLayers,
        LGeoJson,
        LTooltip,
        LPopup,
        LCircle,
        LMarker,
    } from 'vue2-leaflet';
    import Vue from 'vue';
    import {
        GridPlugin,
        RowDD,
        Aggregate,
    } from '@syncfusion/ej2-vue-grids';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import moment from 'moment';
    import axios from 'axios';
    import {
        shipHelpers,
        tools,
        config
    } from '../helpers';
    import {
        eventBus
    } from '../main';
    import '../plugins/Control.scaleNM';
    import StatusDialog from '../components/StatusDialog.vue';
    import WeatherCompass from '../components/WeatherCompass.vue';
    import DistanceAggregate from '../components/DistanceAggregate.vue';
    import DaysAggregate from '../components/DaysAggregate.vue';
    import PortDetails from '../components/PortDetails';
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import am4themes_dark from "@amcharts/amcharts4/themes/dark";

    am4core.useTheme(am4themes_animated);

    require('leaflet-velocity');

    Vue.use(GridPlugin);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'FleetMap',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'l-wms-tile-layer': LWMSTileLayer,
            'v-geojson': LGeoJson,
            LControlLayers,
            LTooltip,
            LPopup,
            LCircle,
            LMarker,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            VueSlider,
            StatusDialog,
            WeatherCompass,
            PortDetails,
        },
        data() {
            return {
                // Leaflet specific properties
                map: null,
                mapKey: 0,
                baseLayerKey: 100,
                tileLayerKey: 1000,
                zoom: 3,
                center: L.latLng(30, 0),
                attribution: '&copy; <a href="http://www.nauticai.com">nauticAi</a>',
                route: {
                    options: {
                        style: {
                            color: '#1E88E5',
                            weight: 1.5,
                            opacity: 0.95
                        }
                    }
                },
                corridor: {
                    options: {
                        style: {
                            color: '#4CAF50',
                            weight: 0.1,
                            opacity: 0.68
                        }
                    }
                },
                shipGeojsonOptions: {
                    style: {
                        color: '#e67e22',
                        weight: 2,
                        opacity: 1
                    }
                },
                pastTrackOptions: {
                    style: {
                        color: '#e67e22',
                        weight: 2,
                    }
                },
                pastTracks: [],
                bounds: null,
                baseUrl: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                baseLayers: [{
                        url: `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`,
                        name: 'Day',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: true,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`,
                        name: 'Night',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${config.apiKey}`,
                        name: 'Satellite hybrid',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=${config.apiKey}`,
                        name: 'Satellite',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `http://viewer.flytomap.com/ftm_tiles/{z}/{x}/{y}.png`,
                        name: 'Nautical vector [ft]',
                        attribution: '<a href="https://flytomap.com/" target="_blank">Powered by FLYTOMAP</a>',
                        visible: false,
                        crossOrigin: false,
                        tileSize: 512,
                    },
                    {
                        url: `https://viewer.flytomap.com/noaatiles/{z}/{x}/{y}.png`,
                        name: 'Nautical raster [ft/m]',
                        attribution: '<a href="https://flytomap.com/" target="_blank">Powered by FLYTOMAP</a>',
                        visible: false,
                        crossOrigin: false,
                        tileSize: 512,
                    },
                ],
                tileLayers: [{
                    url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
                    name: 'Open sea map',
                    zIndex: 500,
                    attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> &copy; <a href="http://www.openseamap.org/">OpenSeaMap</a>',
                    visible: true,
                }],
                layers: [{
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'ECA',
                        visible: false,
                        format: 'image/png',
                        layers: 'eca',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'PSSA',
                        visible: false,
                        format: 'image/png',
                        layers: 'pssa',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'HRA',
                        visible: false,
                        format: 'image/png',
                        layers: 'hra',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'VRA',
                        visible: false,
                        format: 'image/png',
                        layers: 'vra',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'Sea areas',
                        visible: false,
                        format: 'image/png',
                        layers: 'sea',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: '12 NM areas',
                        visible: false,
                        format: 'image/png',
                        layers: '12nm',
                        opacity: 1,
                        zIndex: 500,
                    },
                    {
                        url: `https://geoserver.fleetrangelive.com/geoserver/fr/wms?&authkey=${config.authKey}`,
                        name: 'Ports',
                        visible: false,
                        format: 'image/png',
                        layers: 'ports',
                        opacity: 1,
                        zIndex: 500,
                    }
                ],
                USLayers: [
                    {
                        url: 'https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/WMSServer',
                        name: 'US ENC charts',
                        visible: false,
                        layers: '1,2,3,6',
                        attribution: 'Source: NOAA ENC. This data DOES NOT meet USCG chart carriage requirements for commercial vessels.',
                        format: 'image/png8',
                        opacity: 0.9,
                        zIndex: 500,
                    },
                    // {
                    //     url: 'https://seamlessrnc.nauticalcharts.noaa.gov/arcgis/services/RNC/NOAA_RNC/ImageServer/WMSServer',
                    //     name: 'US raster charts',
                    //     visible: false,
                    //     layers: '0',
                    //     attribution: 'Source: The NOAA_RNC Image Service provides a seamless collarless mosaic of the NOAA Raster Nautical Charts. Source charts are updated once per month.Seamless RNC service DOES NOT meet USCG chart carriage requirements for commercial vessels.',
                    //     format: 'image/png',
                    //     opacity: 0.9,
                    //     zIndex: 500,
                    // },
                    {
                        url: 'http://ienccloud.us/arcgis/services/IENC/USACE_IENC_Master_Service/MapServer/WmsServer',
                        name: 'US inland ENC charts',
                        visible: false,
                        layers: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98',
                        attribution: 'Source: USACE. This data DOES NOT meet chart carriage requirements for commercial vessels.',
                        format: 'image/png32',
                        opacity: 0.9,
                        zIndex: 500,
                    },
                    {
                        url: 'https://gis.boem.gov/arcgis/services/BOEM_BSEE/GOM_Layers/MapServer/WmsServer',
                        name: 'US pipelines',
                        visible: false,
                        layers: '8',
                        attribution: 'Source: This dataset is a compilation of available oil and gas pipeline data and is maintained by BSEE. Pipelines are used to transport and monitor oil and/or gas from wells within the outer continental shelf (OCS) to resource collection locations. Currently, pipelines managed by BSEE are found in Gulf of Mexico and southern California waters.',
                        format: 'image/png8',
                        opacity: 0.9,
                        zIndex: 500,
                    },
                    {
                        url: 'https://gis.boem.gov/arcgis/services/BOEM_BSEE/GOM_Layers/MapServer/WmsServer',
                        name: 'US rigs',
                        visible: false,
                        layers: '10',
                        attribution: 'Source: Locations of structures at and beneath the water surface used for the purpose of exploration and resource extraction. Only platforms in federal Outer Continental Shelf (OCS) waters are included. A database of platforms and rigs is maintained by BSEE',
                        format: 'image/png32',
                        opacity: 0.9,
                        zIndex: 500,
                    },
                    {
                        url: 'https://gis.boem.gov/arcgis/services/BOEM_BSEE/GOM_Layers/MapServer/WmsServer?',
                        name: 'US protraction areas',
                        visible: false,
                        layers: '5',
                        attribution: 'Source: This data set contains a national scale spatial footprint of the outer boundaries of the Bureau of Ocean Energy Management’s (BOEM’s) Official Protraction Diagrams (OPDs) and Leasing Maps (LMs). It is updated as needed.',
                        format: 'image/png32',
                        opacity: 0.9,
                        zIndex: 500,
                    },
                    {
                        url: 'https://gis.boem.gov/arcgis/services/BOEM_BSEE/GOM_Layers/MapServer/WmsServer?',
                        name: 'US lease blocks',
                        visible: false,
                        layers: '4',
                        attribution: 'Source: Outer Continental Shelf (OCS) lease blocks serve as the legal definition for BOEM offshore boundary coordinates used to define small geographic areas within an Official Protraction Diagram (OPD) for leasing and administrative purposes.',
                        format: 'image/png8',
                        opacity: 0.9,
                        zIndex: 500,
                    }
                ],
                activeLayer: null,
                waveValue: '',
                windValue: '',
                currentValue: '',
                eventValue: '',
                waveOptions: {
                    value: null,
                    width: '90%',
                    tooltip: 'always',
                    disabled: false,
                    piecewise: true,
                    // piecewiseLabel: true,
                    formatter(value) {
                        return moment.utc(value).format('D.M HH:mm');
                    },
                    style: {
                        marginLeft: '5%'
                    },
                    data: [''],
                    piecewiseStyle: {
                        backgroundColor: '#ccc',
                        visibility: 'visible',
                        width: '12px',
                        height: '12px'
                    },
                    piecewiseActiveStyle: {
                        backgroundColor: '#3498db'
                    },
                    labelActiveStyle: {
                        color: '#3498db'
                    }
                },
                windOptions: {
                    value: null,
                    width: '90%',
                    tooltip: 'always',
                    disabled: false,
                    piecewise: true,
                    formatter(value) {
                        return moment.utc(value).format('D.M HH:mm');
                    },
                    style: {
                        marginLeft: '5%'
                    },
                    data: [''],
                    piecewiseStyle: {
                        backgroundColor: '#ccc',
                        visibility: 'visible',
                        width: '12px',
                        height: '12px'
                    },
                    piecewiseActiveStyle: {
                        backgroundColor: '#3498db'
                    },
                    labelActiveStyle: {
                        color: '#3498db'
                    }
                },
                currentOptions: {
                    value: null,
                    width: '90%',
                    tooltip: 'always',
                    disabled: false,
                    piecewise: true,
                    formatter(value) {
                        return moment.utc(value).format('D.M HH:mm');
                    },
                    style: {
                        marginLeft: '5%'
                    },
                    data: [''],
                    piecewiseStyle: {
                        backgroundColor: '#ccc',
                        visibility: 'visible',
                        width: '12px',
                        height: '12px'
                    },
                    piecewiseActiveStyle: {
                        backgroundColor: '#3498db'
                    },
                    labelActiveStyle: {
                        color: '#3498db'
                    }
                },
                eventOptions: {
                    value: null,
                    width: '90%',
                    tooltip: 'always',
                    disabled: false,
                    piecewise: true,
                    formatter(value) {
                        return moment.utc(value).format('D.M.Y');
                    },
                    style: {
                        marginLeft: '5%'
                    },
                    data: [''],
                    piecewiseStyle: {
                        backgroundColor: '#ccc',
                        visibility: 'visible',
                        width: '12px',
                        height: '12px'
                    },
                    piecewiseActiveStyle: {
                        backgroundColor: '#3498db'
                    },
                    labelActiveStyle: {
                        color: '#3498db'
                    }
                },
                windAnimationLayer: null,
                timesteps: [],
                windAnimationValue: '',
                windAnimationOptions: {
                    value: null,
                    width: '90%',
                    tooltip: 'always',
                    disabled: false,
                    piecewise: true,
                    // piecewiseLabel: true,
                    formatter(value) {
                        const time = value.split('.json')[0];
                        return moment(time.substr(0, 8)).add(time.substr(8, 11), 'hours').format('D.M HH:mm');
                    },
                    style: {
                        marginLeft: '5%'
                    },
                    data: [''],
                    piecewiseStyle: {
                        backgroundColor: '#ccc',
                        visibility: 'visible',
                        width: '12px',
                        height: '12px'
                    },
                    piecewiseActiveStyle: {
                        backgroundColor: '#3498db'
                    },
                    labelActiveStyle: {
                        color: '#3498db'
                    }
                },
                inDragging: false,
                drawer: true,
                startDateMenu: false,
                startTimeMenu: false,
                portLoading: false,
                newPort: {},
                portSearch: null,
                ports: [],
                speed: 12,
                portHours: 'Port default',
                routePlanner: {
                    startTime: moment().format('HH:mm'),
                    startDate: moment().format('YYYY-MM-DD'),
                    ports: [],
                },
                portIds: [],
                selectedRowIndex: null,
                hours: ['Port default', '0', '1', '2', '3', '4', '5', '6', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22',
                    '23', '24', '36', '48', '72', '96', '120'
                ],
                events: [],
                footerDistanceTotal() {
                    return {
                        template: DistanceAggregate
                    };
                },
                footerDaysTotal() {
                    return {
                        template: DaysAggregate
                    };
                },
                fillColor: '#7face4',
                activePanel: 'General',
                panels: [{
                        title: 'Close',
                        icon: 'mdi-close'
                    },
                    {
                        title: 'General',
                        icon: 'mdi-ship-wheel'
                    },
                    {
                        title: 'Logbook',
                        icon: 'mdi-book-open-variant'
                    },
                    {
                        title: 'Maranics',
                        icon: 'mdi-text-box-check-outline'
                    },
                    {
                        title: 'Norppa',
                        icon: 'mdi-text-box-outline'
                    },
                ],
                showPanel: false,
                showCircles: true,
                showRoutes: true,
                ship: null,
                streams: null,
                sldpPlayers: {},
                checklists: [],
                search: '',
                searchLogbook: '',
                filter: [],
                dialog: false,
                checklistItems: [],
                checklistTitles: null,
                eventMarker: {},
                aisTargets: [],
                shipWeather: null,
                aisTargetsInterval: null,
                checklistInterval: null,
                logbookInterval: null,
                shipInterval: null,
                weatherInterval: null,
                motionData: [],
                chart: null,
                triggers: [],
                triggerStyle: {
                    style: {
                        color: '#27ae60',
                        weight: 1,
                        opacity: 0.6
                    }
                },
                waypointsGeojson: null,
                winterShips: [],
                schedule: [],
                panel: null,
                nextIndex: null,
                showDetails: false,
            };
        },
        mounted() {
            if (this.features && this.features.layers && this.features.layers.windAnimation === 1) {
                axios.get('https://geoserver.fleetrangelive.com/geoserver/www/json/timesteps.txt')
                    .then((response) => {
                        this.windAnimationOptions.data = response.data.split('\n').filter((o) => o !== '');
                        this.windAnimationValue = this.windAnimationOptions.data.filter((o) => {
                            // Find closest timestamp to present time
                            let time = o.split('.json')[0];
                            time = moment.utc(time.substr(0, 8)).add(time.substr(8, 11), 'hours');
                            return Math.abs(time.unix() - moment.utc().unix()) <= 3 * 60 * 60;
                        })[0];
                    }).catch((e) => {
                        console.error(e);
                    });
            }
            if (this.features && this.features.layers && this.features.layers.balticIce === 1) {
                this.fetchIceData().then((data) => {
                    this.waypointsGeojson = data && data.waypoints ? data.waypoints : null;
                    if (data.ships && data.ships.length > 0) {
                        let ships = data.ships;
                        for (let i = 0, il = ships.length; i < il; i++) {
                            const latLng = L.latLng(ships[i].coordinates[1], ships[i].coordinates[0]);
                            if (latLng) {
                                const icon = L.icon({
                                    iconUrl: require(`@/assets/img/ships/${shipHelpers.shipIcon(0, 'ts', 123456, null, ships[i].speed)}`),
                                    iconSize: [11, 30], 
                                    iconAnchor: [5.5, 15],
                                })
                                this.winterShips.push({
                                    latLng,
                                    tooltip: ships[i].shipname,
                                    course: ships[i].course,
                                    icon,
                                    options: {permanent: true, direction: 'top', offset: L.point(0, -10), opacity: 0.8 }
                                })
                            }
                        }
                    }
                });
            }
            
            this.$nextTick(() => {
                this.addControls();
            });
            if (this.getDarkMode()) {
                this.baseLayers = this.baseLayers.map((o) => {
                    if (o.name === 'Night') {
                        o.visible = true;
                    } else {
                        o.visible = false;
                    }
                    return o;
                });
                am4core.useTheme(am4themes_dark);
            } else {
                this.baseLayers = this.baseLayers.map((o) => {
                    if (o.name === 'Day') {
                        o.visible = true;
                    } else {
                        o.visible = false;
                    }
                    return o;
                });
            }
            this.showRoutes = localStorage.getItem('showRoutes') === '0' ? false : true;
            this.showCircles = localStorage.getItem('showCircles') === '0' ? false : true;
            eventBus.$on('darkMode', (dark) => {
                if (dark) {
                    this.baseLayers = this.baseLayers.map((o) => {
                        if (o.name === 'Night') {
                            o.visible = true;
                        } else {
                            o.visible = false;
                        }
                        return o;
                    });
                } else {
                    this.baseLayers = this.baseLayers.map((o) => {
                        if (o.name === 'Day') {
                            o.visible = true;
                        } else {
                            o.visible = false;
                        }
                        return o;
                    });
                }
                ++this.baseLayerKey;
                ++this.tileLayerKey;
            });
        },
        watch: {
            portSearch(val) {
                val && val !== this.select && this.portQuerySelections(val);
            },
            dark(newValue) {
                this.chart.dispose();
                this.chart = null;
                if (newValue) {
                    am4core.useTheme(am4themes_dark);
                } else {
                    am4core.unuseTheme(am4themes_dark);
                }
                // Create charts
                if (this.motionData && this.motionData.length > 0) {
                    this.createCharts();
                }
            }
        },
        computed: {
            ...mapGetters({
                features: 'authentication/features',
                firstDayOfWeek: 'data/getFirstDayOfWeek',
                records: 'logbook/getRecords',
                user: 'authentication/getUser',
                dark: 'users/getDarkMode',
            }),
            userLevel() {
                return this.getUser().user.level;
            },
            geojsonLabel() {
                return {
                    onEachFeature: this.onEachFeatureFunction
                };
            },
            corridorStyleFunction() {
                return () => {
                    return {
                        color: '#4CAF50',
                        weight: 0.1,
                        opacity: 0.68
                    }
                }
            },
            onEachFeatureFunction() {
                return (feature, layer) => {
                    layer.bindTooltip(
                        "<div><b>" +
                        feature.properties.ship +
                        "</b><br/> " +
                        feature.properties.route +
                        "</div>", {
                            permanent: false,
                            sticky: true
                        }
                    );
                };
            },
            panelsFiltered() {
                return this.panels.filter((o) => {
                    if (o.title === 'General' || o.title === 'Close' || this.features && this.features.mapPanels && this.features.mapPanels[o.title] === 1) {
                        if (o.title === 'Logbook') {
                            if (this.ship.logDb && this.user.user.logbooks && this.user.user.logbooks.includes(this.ship.logDb)) {
                                return o;
                            }
                        } else {
                            return o;
                        }
                    }
                })
            },
            colors() {
                if (this.getDarkMode()) {
                    return ['#0A1597', '#1734F9', '#106DFB', '#009BFC', '#00b38f', '#00b36e', '#00b341', '#59c804', '#c9c603', '#ff9d00', '#FF6216', '#FF4C13',
                        '#FF030B', '#BC0105', '#80003e', '#78003B', '#B50058', '#F30077'
                    ];
                }
                return ['#091490', '#0623e0', '#035ce2', '#007ecc', '#008066', '#00804f', '#00802f', '#439603', '#b0ad03', '#cc7e00', '#e64d00', '#e63600',
                    '#e60008', '#b20104', '#660031', '#4d0025'
                ];
            },
            ships() {
                const dynamic = JSON.parse(JSON.stringify(this.getDynamic()));
                const ships = JSON.parse(JSON.stringify(this.getShips()));
                if (dynamic && dynamic.ais && ships && ships.length > 0) {
                    for (const key in dynamic.ais) {
                        if (dynamic.ais.hasOwnProperty(key)) {
                            const ship = ships.find((o) => o.mmsi === dynamic.ais[key].mmsi);
                            if (ship) {
                                dynamic.ais[key].routeGeojson = ship.routeGeojson;
                                if (dynamic.ais[key].routeGeojson) {
                                    dynamic.ais[key].routeGeojson.properties = {};
                                    dynamic.ais[key].routeGeojson.properties.ship = ship.name;
                                    dynamic.ais[key].routeGeojson.properties.route = ship.routeName;
                                    // Check if dateline is crossed
                                    let datelineCrossed = dynamic.ais[key].routeGeojson.coordinates[0].findIndex((o) => o[0] > 180);
                                    if (datelineCrossed !== -1 && dynamic.ais[key].longitude < 0) {
                                        for (let i = 0, il = dynamic.ais[key].routeGeojson.coordinates.length; i < il; i++) {
                                            dynamic.ais[key].routeGeojson.coordinates[i] = dynamic.ais[key].routeGeojson.coordinates[i].map((o) => {
                                                o[0] = parseFloat(o[0]) - 360;
                                                return o;
                                            })
                                        }
                                    }
                                    dynamic.ais[key].corridorGeojson = ship.corridorGeojson;
                                    if (dynamic.ais[key].corridorGeojson) {
                                        dynamic.ais[key].corridorGeojson.properties = {};
                                        dynamic.ais[key].corridorGeojson.properties.ship = ship.name;
                                        dynamic.ais[key].corridorGeojson.properties.route = ship.routeName;
                                        // Check if dateline is crossed
                                        if (datelineCrossed !== -1 && dynamic.ais[key].longitude < 0) {
                                            for (let i = 0, il = dynamic.ais[key].corridorGeojson.coordinates.length; i < il; i++) {
                                                dynamic.ais[key].corridorGeojson.coordinates[i] = dynamic.ais[key].corridorGeojson.coordinates[i].map((o) => {
                                                    o[0] = parseFloat(o[0]) - 360;
                                                    return o;
                                                })
                                            }
                                        }
                                    }
                                }
                                if (ship.a && ship.b && ship.c && ship.d) {
                                    dynamic.ais[key].a = ship.a;
                                    dynamic.ais[key].b = ship.b;
                                    dynamic.ais[key].c = ship.c;
                                    dynamic.ais[key].d = ship.d;
                                    dynamic.ais[key].shipGeojson = shipHelpers.shipGeojson(dynamic.ais[key]);
                                }
                            }
                            if (dynamic.ais[key].latitude && dynamic.ais[key].longitude) {
                                dynamic.ais[key].latLng = L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude);

                                // Ship predictor arrow marker
                                const arrow = shipHelpers.vectorSvg(dynamic.ais[key].sog, 'white');
                                if (arrow) {
                                    dynamic.ais[key].predictor = {
                                        latLng: L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude),
                                        direction: shipHelpers.headingOrCog(dynamic.ais[key].heading, dynamic.ais[
                                            key].cog, true),
                                        icon: L.icon({
                                            iconUrl: arrow.image,
                                            iconSize: [8, arrow.height],
                                            iconAnchor: [4, arrow.height],
                                        }),
                                        rotationOrigin: 'center bottom',
                                    };
                                }

                                // Create circle around ais position
                                dynamic.ais[key].circles = [];
                                const radiuses = [926, 1852, 2778];
                                for (const radius of radiuses) {
                                    dynamic.ais[key].circles.push({
                                        center: L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude),
                                        radius,
                                        color: 'rgba(150, 150, 150, 0.3)',
                                        weight: 0.5
                                    });
                                }
                            } else {
                                dynamic.ais[key].latLng = L.latLng(0, 0);
                                dynamic.ais[key].predictor = null;
                                dynamic.ais[key].circles = null;
                            }
                            dynamic.ais[key].heading = shipHelpers.headingOrCog(dynamic.ais[key].heading, dynamic.ais[
                                key].cog);
                            const prefix = dynamic.ais[key].external === 1 ? 'ts' : 'os';
                            const icon = shipHelpers.shipIcon(dynamic.ais[key].navstat, prefix, dynamic.ais[key].mmsi, null, dynamic.ais[key].sog);
                            dynamic.ais[key].icon = (
                                L.icon({
                                    iconUrl: require(`@/assets/img/ships/${icon}`),
                                    iconSize: [11, 30], // size of the icon
                                    iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                                })
                            );
                            dynamic.ais[key].tooltip = dynamic.ais[key].name;
                            dynamic.ais[key].tooltipOptions = shipHelpers.shipTooltipOptions(dynamic.ais[key], L);
                            // dynamic.ais[key].popup = shipHelpers.shipPopup(dynamic.ais[key]);
                            dynamic.ais[key].imo = ship.imo;
                            dynamic.ais[key].callSign = ship.callSign;
                            dynamic.ais[key].dwt = ship.dwt;
                            dynamic.ais[key].cuf = ship.cuf;
                            dynamic.ais[key].streamParams = ship.streamParams;
                            dynamic.ais[key].logDb = ship.logDb;
                        }
                    }
                    return dynamic.ais;
                }
                return [];
            },
            checklistsFiltered() {
                let res = this.checklists;
                if (this.search && this.search.length > 2) {
                    res = this.checklists.filter((o) => {
                        return o.itemTitle.toLowerCase().includes(this.search.toLowerCase()) ||
                            o.itemSecondaryTitle.toLowerCase().includes(this.search.toLowerCase()) ||
                            o.updatedByPosition.toLowerCase().includes(this.search.toLowerCase())
                    });
                }
                return res;
            },
            triggerGeoOptions() {
                return {
                    onEachFeature: this.onEachFeatureFunctionTrigger
                };
            },
            onEachFeatureFunctionTrigger() {
                return (feature, layer) => {
                    if (feature.properties && feature.properties.name) {
                        layer.bindTooltip(
                        '<div>' + feature.properties.name + '</div>',
                        { permanent: false, sticky: true }
                        );
                    }
                };
            },
        },
        created() {
            this.callFetchPastTrack();
            this.fetchShips().then(() => {
                this.setBounds();
            });
            this.fetchWmsCapabilities('geoserver').then((data) => {
                this.waveOptions.data = data.filter((layer) => layer.Name[0] === 'Global_significant_wave_gradient')[0].Dimension[0]._.split(',');
                const timeNow = moment.utc().unix();
                this.waveValue = this.waveOptions.data.filter((o) => Math.abs(moment.utc(o).unix() - timeNow) <= 1.5 * 60 * 60)[0]; // Find closest timestamp to present time 
                this.windOptions.data = data.filter((layer) => layer.Name[0] === 'wind_barbs_gfs')[0].Dimension[0]._.split(',');
                this.windValue = this.windOptions.data.filter((o) => Math.abs(moment.utc(o).unix() - timeNow) <= 3 * 60 * 60)[0]; // Find closest timestamp to present time 
                if (this.features && this.features.layers && this.features.layers.globalOceanCurrents === 1) {
                    this.currentOptions.data = data.filter((layer) => layer.Name[0] === 'global_currents')[0].Dimension[0]._.split(',');
                    this.currentValue = this.currentOptions.data.find((o) => Math.abs(moment.utc(o).unix() - timeNow) <= 0.5 * 60 * 60); // Find closest timestamp to present time 
                }
            });
        },
        beforeDestroy() {
            clearInterval(this.pastTrackInterval);
            clearInterval(this.aisTargetsInterval);
            clearInterval(this.shipInterval);
            clearInterval(this.checklistInterval);
            clearInterval(this.logbookInterval);
            clearInterval(this.weatherInterval);
            eventBus.$off('darkMode');
            if (this.chart !== null) {
                this.chart.dispose();
            }
            if (this.sldpPlayers && this.sldpPlayers.length > 0) {
                for (const key in this.sldpPlayers) {
                    if (Object.hasOwnProperty.call(this.sldpPlayers, key)) {
                        this.sldpPlayers[key].destroy();
                    }
                }
            }
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getPastTrack: 'data/getPastTrack',
                getShips: 'data/getShips',
                getEventHistory: 'data/getEventHistory',
                getWmsCapabilities: 'data/getWmsCapabilities',
                getUser: 'authentication/getUser',
            }),
            ...mapActions({
                fetchPastTrack: 'data/fetchPastTrack',
                fetchShips: 'data/fetchShips',
                fetchWmsCapabilities: 'data/fetchWmsCapabilities',
                fetchPorts: 'data/fetchPorts',
                fetchSchedulerRoutes: 'data/fetchSchedulerRoutes',
                fetchEventHistory: 'data/fetchEventHistory',
                fetchStreamUrl: 'data/fetchStreamUrl',
                sendCameraCommand: 'data/sendCameraCommand',
                sendCameraCommandImage: 'data/sendCameraCommandImage',
                fetchChecklists: 'data/fetchChecklists',
                fetchNorppaData: 'data/fetchNorppaData',
                fetchChecklistTitles: 'data/fetchChecklistTitles',
                saveChecklistItems: 'data/saveChecklistItems',
                fetchAisTargets: 'data/fetchAisTargets',
                fetchShipWeather: 'data/fetchShipWeather',
                fetchRecords: 'logbook/fetchRecords',
                fetchMotionData: 'data/fetchMotionData',
                fetchTriggers: 'logbook/fetchTriggers',
                fetchIceData: 'data/fetchIceData',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertClear: 'alert/clear',
                alertSuccess: 'alert/success',
            }),
            callFetchPastTrack() {
                this.fetchPastTrack().then((data) => {
                    this.pastTracks = data;
                });
                this.pastTrackInterval = setInterval(() => {
                    this.fetchPastTrack().then((data) => {
                        this.pastTracks = data;
                    });
                }, 60 * 1000);
            },
            addControls() {
                L.control.scale().addTo(this.$refs.map.mapObject);
                L.control.mousePosition({
                    emptyString: '0º N 0º E',
                    separator: ' ',
                    lngFormatter(num) {
                        return tools.ConvertDDToDMS(num, true);
                    },
                    latFormatter(num) {
                        return tools.ConvertDDToDMS(num, false);
                    }
                }).addTo(this.$refs.map.mapObject);
            },
            setBounds() {
                if (this.ships && this.ships.length > 1) {
                    try {
                        this.bounds = L.latLngBounds(this.ships.map((ship) => [ship.latitude, ship.longitude])).pad(0.1);
                    } catch (e) {
                        console.warn('Ship is missing position information.');
                    }
                } else if (this.ships && this.ships.length === 1) {
                    this.center = L.latLng(this.ships[0].latitude, this.ships[0].longitude);
                    this.zoom = 7;
                }
            },
            toggleWindAnimationLayer() {
                if (this.activeLayer === 'windAnimation') {
                    if (this.inDragging) {
                        return;
                    }
                    if (this.windAnimationLayer !== null) {
                        axios.get(`https://geoserver.fleetrangelive.com/geoserver/www/json/${this.windAnimationValue}`)
                            .then((response) => {
                                this.windAnimationLayer.setData(response.data);
                            }).catch((e) => {
                                console.error(e);
                            });
                    } else {
                        this.inDragging = true;
                        axios.get(`https://geoserver.fleetrangelive.com/geoserver/www/json/${this.windAnimationValue}`)
                            .then((response) => {
                                this.windAnimationLayer = L.velocityLayer({
                                    displayValues: true,
                                    displayOptions: {
                                        velocityType: 'Wind',
                                        position: 'bottomleft',
                                        emptyString: 'No wind data',
                                        angleConvention: 'meteoCW',
                                        displayPosition: 'bottomleft',
                                        displayEmptyString: 'No wind data',
                                        speedUnit: 'm/s'
                                    },
                                    data: response.data, // see demo/*.json, or wind-js-server for example data service

                                    // OPTIONAL
                                    minVelocity: 0, // used to align color scale
                                    maxVelocity: 30, // used to align color scale
                                    velocityScale: 0.007, // modifier for particle animations, arbitrarily defaults to 0.005
                                    colorScale: this.colors, // define your own array of hex/rgb colors
                                    // onAdd: null, // callback function
                                    // onRemove: null, // callback function
                                    opacity: 0.97, // layer opacity, default 0.97
                                    // optional pane to add the layer, will be created if doesn't exist
                                    // leaflet v1+ only (falls back to overlayPane for < v1)
                                    paneName: 'overlayPane'
                                }).addTo(this.$refs.map.mapObject);
                                this.inDragging = false;
                            }).catch((e) => {
                                console.error(e);
                                this.inDragging = false;
                            });
                    }
                } else if (this.windAnimationLayer) {
                    this.$refs.map.mapObject.removeLayer(this.windAnimationLayer);
                    this.windAnimationLayer = null;
                }
            },
            random() {
                return Math.random();
            },
            initRoutePlanner() {
                this.routePlanner = {
                    startTime: moment().format('HH:mm'),
                    startDate: moment().format('YYYY-MM-DD'),
                    ports: [],
                    routes: [],
                };
                this.portIds = [];
                this.mapKey++; // Refresh map
                this.$nextTick(() => {
                    this.addControls();
                });
                if (this.activeLayer !== 'routePlanner') {
                    this.setBounds();
                }
            },
            portQuerySelections(v) {
                if (v.length > 2) {
                    this.portLoading = true;
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports = data;
                        this.portLoading = false;
                    });
                }
            },
            portItemText(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            addPort() {
                if (!this.routePlanner.startTime || !this.routePlanner.startDate) {
                    this.alertError('Set start date and time first.');
                }
                if (this.routePlanner.ports.length === 0) { // First port
                    this.newPort.etd = moment(`${this.routePlanner.startDate}T${this.routePlanner.startTime}`).toDate();
                    if (typeof this.newPort.latitude !== 'undefined' && typeof this.newPort.longitude !== 'undefined') {
                        this.center = L.latLng(this.newPort.latitude, this.newPort.longitude);
                        this.zoom = 7;
                    }
                    this.routePlanner.ports.push(this.newPort);
                    this.newPort = {};
                    this.$refs.grid.refresh();
                } else {
                    this.routePlanner.ports.push(this.newPort);
                    this.getRoutes();
                }
            },
            getRoutes(reorder) {
                if (this.routePlanner.ports.length === 0) {
                    this.routePlanner.routes = [];
                    return;
                }
                if (reorder) {
                    this.routePlanner.ports[0].eta = null;
                    this.routePlanner.ports[0].distance = null;
                    this.routePlanner.ports[0].days = null;
                    this.routePlanner.ports[0].etd = moment(`${this.routePlanner.startDate}T${this.routePlanner.startTime}`).toDate();
                }
                this.fetchSchedulerRoutes({
                    data: this.routePlanner.ports,
                }).then((data) => {
                    if (!data || data.length === 0) {
                        return;
                    }
                    this.routePlanner.ports = data;
                    this.calculateRouteTimes(false);
                    if (!data[data.length - 1].geojson) {
                        this.alertError('Failed to calculate route, please try again later.');
                    }
                    // Show routes on map
                    this.routePlanner.routes = data.filter((o) => o.geojson).map((o) => o.geojson);
                    if (this.routePlanner.routes.length > 0) { // Set map bounds to route
                        const routes = JSON.parse(JSON.stringify(this.routePlanner.routes));
                        this.bounds = L.latLngBounds(routes.map((route) => route.coordinates.map((o) => o.reverse()))).pad(0.1);
                    }

                    this.newPort = {};
                    this.portIds = this.routePlanner.ports.map((o) => o.portId);
                    this.$refs.grid.refresh();
                });
            },
            calculateRouteTimes(recalculate) {
                if (recalculate) {
                    this.routePlanner.ports[0].etd = moment(`${this.routePlanner.startDate}T${this.routePlanner.startTime}`).toDate();
                }
                // Calculate ETA & ETD times
                for (let i = 0, il = this.routePlanner.ports.length; i < il; i++) {
                    if (i > 0) {
                        let eta = null;
                        let etd = null;
                        if (i === this.selectedRowIndex || this.selectedRowIndex === null) {
                            this.routePlanner.ports[i].portHours = this.portHours === 'Port default' ? this.routePlanner.ports[i].defaultHours : parseInt(this
                                .portHours, 10);
                            this.routePlanner.ports[i].speed = Math.round(this.speed * 10) / 10;
                        }
                        this.routePlanner.ports[i].distance = Math.round(this.routePlanner.ports[i].distance * 10) / 10;
                        eta = moment(this.routePlanner.ports[i - 1].etd).add(this.routePlanner.ports[i].distance / this.routePlanner.ports[i].speed * 60 * 60,
                            'seconds');
                        etd = eta.clone().add(this.routePlanner.ports[i].portHours, 'hours');
                        this.routePlanner.ports[i].daysSea = Math.round(eta.diff(this.routePlanner.ports[i - 1].etd, 'days', true) * 10) / 10;
                        this.routePlanner.ports[i].daysTotal = Math.round(etd.diff(this.routePlanner.ports[i - 1].etd, 'days', true) * 10) / 10;
                        this.routePlanner.ports[i].days = `${this.routePlanner.ports[i].daysTotal}/${this.routePlanner.ports[i].daysSea}`;
                        this.routePlanner.ports[i].eta = moment(eta).toDate();
                        this.routePlanner.ports[i].etd = moment(etd).toDate();
                    }
                }
                if (recalculate) {
                    this.$refs.grid.refresh();
                }
            },
            customSumAggregateFn(data) {
                // console.log(data);
                let daysTotal = 0;
                let daysSea = 0;
                data = data && data.result ? data.result : data;
                data.map((o) => {
                    if (!isNaN(o.daysTotal)) {
                        daysTotal += parseFloat(o.daysTotal);
                    }
                    if (!isNaN(o.daysSea)) {
                        daysSea += parseFloat(o.daysSea);
                    }
                });
                return `${Math.round(daysTotal * 10) / 10}/${Math.round(daysSea * 10) / 10}`;
            },
            dropEnd() {
                setTimeout(() => {
                    if (this.routePlanner && this.routePlanner.ports && this.routePlanner.ports.length >= 2) {
                        // is port order changed?
                        const newIds = this.routePlanner.ports.map((o) => o.portId);
                        if (!(this.portIds.every((value, index) => value === newIds[index]))) {
                            console.log('order changed!');
                            this.portIds = newIds;
                            this.getRoutes(true);
                        }
                    }
                }, 100);
            },
            deletePort() {
                const selectedRow = this.$refs.grid.getSelectedRowIndexes()[0];
                if (selectedRow !== undefined) {
                    this.routePlanner.ports.splice(selectedRow, 1);
                    this.getRoutes(true);
                } else {
                    this.alertError('Select port to remove first');
                }
                this.$refs.grid.refresh();
            },
            rowSelected(args) {
                // console.log(args.rowIndex)
                this.selectedRowIndex = args.rowIndex;
            },
            rowDeselected() {
                this.selectedRowIndex = null;
            },
            customizeCell(args) {
                if (this.dark) {
                    args.cell.classList.add('light-text');
                }
                if (args.column.field === 'eta') {
                    if (moment(args.data.eta).format('d') === '6') {
                        args.cell.classList.add('saturday');
                    } else if (moment(args.data.eta).format('d') === '0') {
                        args.cell.classList.add('sunday');
                    }
                }
                if (args.column.field === 'etd') {
                    if (moment(args.data.etd).format('d') === '6') {
                        args.cell.classList.add('saturday');
                    } else if (moment(args.data.etd).format('d') === '0') {
                        args.cell.classList.add('sunday');
                    }
                }
            },
            toggleEventHistory(init) {
                if (this.activeLayer === 'eventHistory') {
                    if (this.inDragging) {
                        return;
                    }
                    if (init) { // Feature inited
                        this.eventOptions.data = [];
                        for (let i = 30; i >= 0; i--) {
                            let time = moment.utc().subtract(i, 'd').format().split('T');
                            time = time[0] + 'T12:00:00Z';
                            this.eventOptions.data.push(time);
                        }
                        this.eventValue = this.eventOptions.data[this.eventOptions.data.length - 1];
                        if (this.getEventHistory().length === 0) {
                            this.fetchEventHistory(moment.utc().format()).then((data) => {
                                this.events = this.initEvents(data);
                                // console.log(this.events);
                            });
                        } else {
                            this.events = this.initEvents(this.getEventHistory());
                        }
                    } else {
                        this.inDragging = true;
                        this.fetchEventHistory(this.eventValue).then((data) => {
                            this.events = this.initEvents(data);
                        }, (error) => {
                            console.error(error);
                        }).finally(() => this.inDragging = false);
                    }
                } else {
                    this.events = [];
                }
            },
            initEvents(data) {
                if (data.length > 0) {
                    data = data.map((o) => {
                        if (o.latitude && o.longitude) {
                            o.latLng = L.latLng(o.latitude, o.longitude);
                            const icon = shipHelpers.shipIcon(o.navstat, 'os', o.mmsi, null, o.sog);
                            o.icon = (
                                L.icon({
                                    iconUrl: require(`@/assets/img/ships/${icon}`),
                                    iconSize: [11, 30], // size of the icon
                                    iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                                })
                            );
                            o.heading = shipHelpers.headingOrCog(o.heading, o.cog);
                            o.tooltipOptions = {
                                permanent: true,
                                direction: 'top',
                                offset: L.point(0, -10),
                                opacity: 0.8,
                            };
                            o.popupOptions = {};
                            let severity = 'Routine';
                            if (o.severity === 2) {
                                o.tooltipOptions.className = 'tooltip-error';
                                severity = 'Critical';
                            } else if (o.severity === 1) {
                                o.tooltipOptions.className = 'tooltip-warn';
                                severity = 'Notification';
                            } else {
                                o.tooltipOptions.className = 'tooltip-valid';
                            }
                            o.popup = `<b style="margin-bottom:5px;margin-right:10px">${o.name} - ${severity} event</b><br/>
                                ${o.description}<br/>
                                ${moment.utc(o.time).format('D.M HH:mm')} UTC<br/>
                                <a style="color:#0984e3 !important" href="/events/${o.id}">Click to open event</a>`;
                        }
                        return o;
                    });
                }
                return data;
            },
            mapSize() {
                let style = '';
                if (this.activeLayer === 'routePlanner' && !this.showPanel) {
                    style = 'left:540px; width:calc(100% - 540px);'
                } else if (this.activeLayer === 'routePlanner' && this.showPanel) {
                    style = 'left:540px; width:calc(100% - 970px); right:486px;'
                } else if (this.showPanel) {
                    style = 'width:calc(100% - 486px); right:486px;'
                }
                return style;
            },
            initPanel(ship) {
                this.checklists = [];
                this.aisTargets = [];
                this.triggers = [];
                this.shipWeather = null;
                clearInterval(this.aisTargetsInterval);
                clearInterval(this.checklistInterval);
                clearInterval(this.logbookInterval);
                clearInterval(this.shipInterval);
                clearInterval(this.weatherInterval);
                this.aisTargetsInterval = null;
                this.checklistInterval = null;
                this.logbookInterval = null;
                this.shipInterval = null;
                this.weatherInterval = null;
                if (this.streams && this.streams.length > 0) {
                    for (let i = 0; i < this.streams.length; i++) {
                        this.endStream(i);
                    }
                }
                if (!ship) {
                    this.showPanel = false;
                    this.ship = null;
                    this.activePanel = 'General';
                    this.eventMarker = {};
                    return;
                }
                if (!this.ship) {
                    this.showPanel = true;
                } else if (ship.id === this.ship.id) {
                    this.showPanel = false;
                }
                // console.log(ship);
                if (this.showPanel) { // init panel
                    this.ship = ship;
                    this.streams = ship.streamParams;
                    this.bounds = L.latLngBounds([
                        [ship.latitude, ship.longitude]
                    ]).pad(1);
                    this.zoom = 10;
                    this.updateWeather();
                    if (!this.weatherInterval) {
                        this.weatherInterval = setInterval(() => {
                            this.updateWeather();
                        }, 30*60*1000);
                    }
                    this.updateAisTargets();
                    if (!this.aisTargetsInterval) {
                        this.aisTargetInterval = setInterval(() => {
                            this.updateAisTargets();
                        }, 1*60*1000);
                    }
                    this.fetchMotionData(this.ship.id).then((data) => {
                        this.motionData = data.map(o => {
                            o.time = moment.utc(o.time).toDate();
                            return o;
                        });
                        if (this.motionData.length > 0) {
                            if (this.chart !== null) {
                                this.chart.dispose();
                            }
                            this.createCharts();
                        }
                    })
                    if (!this.shipInterval) {
                        this.shipInterval = setInterval(() => {
                            if (this.ship.id && this.activePanel !== 'Logbook') {
                                this.ship = this.ships.filter((o) => o.id === this.ship.id)[0];
                                this.$refs.map.mapObject.panTo(L.latLng(this.ship.latitude, this.ship.longitude));
                            }
                        }, 10*1000);
                    }
                    if (this.ship && this.ship.logDb) {
                        this.fetchTriggers(this.ship.logDb).then((data) => {
                            this.triggers = data;
                        })
                    }
                } else {
                    this.ship = null;
                }
            },
            createCharts() {
                this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
                this.chart.dateFormatter.utc = true;
                this.chart.paddingRight = 20;
                this.chart.data = this.motionData;

                const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.tooltipDateFormat = 'dd.MM HH:mm:ss';
                dateAxis.renderer.grid.template.location = 0;

                const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.tooltip.disabled = true;
                valueAxis.renderer.labels.template.fill = am4core.color('#3498db');
                valueAxis.renderer.width = 30;
                valueAxis.min = 0;
                valueAxis.max = 4;
                valueAxis.maxZoomFactor = 10;

                let valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis2.tooltip.disabled = true;
                valueAxis2.renderer.grid.template.strokeDasharray = "2,3";
                valueAxis2.renderer.labels.template.fill = am4core.color("#e67e22");
                valueAxis2.renderer.width = 30;
                valueAxis2.renderer.opposite = true;
                valueAxis2.min = 0;
                valueAxis2.max = 30;

                const series = this.chart.series.push(new am4charts.LineSeries());
                series.name = 'Guest uncomfort index last 6 hours';
                series.dataFields.dateX = 'time';
                series.dataFields.valueY = 'motionIndex';
                series.xAxis = dateAxis;
                series.tooltipText = 'Motion index: {valueY}';
                series.fill = am4core.color('#3498db');
                series.stroke = am4core.color('#3498db');
                series.strokeWidth = 1;
                series.yAxis = valueAxis;

                const series2 = this.chart.series.push(new am4charts.LineSeries());
                series2.name = 'SOG';
                series2.dataFields.dateX = 'time';
                series2.dataFields.valueY = 'sog';
                series2.xAxis = dateAxis;
                series2.tooltipText = 'SOG: {valueY}';
                series2.fill = am4core.color('#e67e22');
                series2.stroke = am4core.color('#e67e22');
                series2.strokeWidth = 1;
                series2.yAxis = valueAxis2;

                this.chart.cursor = new am4charts.XYCursor();
                this.chart.cursor.xAxis = dateAxis;

                this.chart.legend = new am4charts.Legend();
                this.chart.legend.zIndex = 100;
                this.chart.legend.opacity = 0.8;
                this.chart.legend.useDefaultMarker = true;
                const markerTemplate = this.chart.legend.markers.template;
                markerTemplate.width = 12;
                markerTemplate.height = 12;
                this.chart.legend.itemContainers.template.paddingTop = 0;
                this.chart.legend.itemContainers.template.paddingBottom = 0;
                this.chart.legend.position = 'bottom';

                dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                valueAxis.renderer.grid.template.strokeOpacity = 0.07;
            },
            updateWeather() {
                if (this.ship && this.ship.id) {
                    this.fetchShipWeather(this.ship.id).then((data) => {
                        this.shipWeather = data;
                    });
                }
            },
            updateAisTargets() {
                if (this.ship && this.ship.id) {
                    this.fetchAisTargets(this.ship.id).then((data) => {
                        // console.log(data);
                        if (!data || data.length === 0) {
                            return;
                        }
                        this.aisTargets = data.map((o) => {
                            let target = {};
                            target.mmsi = o[0];
                            target.name = o[1];
                            target.longitude = Number.parseFloat(o[2]);
                            target.latitude = Number.parseFloat(o[3]);
                            target.time = o[4];
                            target.sog = Number.parseFloat(o[5]);
                            target.cog = Number.parseFloat(o[6]);
                            target.heading = Number.parseFloat(o[7]);
                            target.navstat = o[8];
                            target.imo = o[10];
                            target.draught = o[16];
                            target.destination = o[17];
                            target.eta = o[18] !== '0000-00-00 00:00:00' ? o[18] : '';

                            if (target.latitude && target.longitude) {
                                target.latLng = L.latLng(target.latitude, target.longitude);
                                // Ship predictor arrow marker
                                const arrow = shipHelpers.vectorSvg(target.sog, 'white');
                                if (arrow) {
                                    target.predictor = {
                                        latLng: L.latLng(target.latitude, target.longitude),
                                        direction: shipHelpers.headingOrCog(target.heading, target.cog, true),
                                        icon: L.icon({
                                            iconUrl: arrow.image,
                                            iconSize: [8, arrow.height],
                                            iconAnchor: [4, arrow.height],
                                        }),
                                        rotationOrigin: 'center bottom',
                                    };
                                }
                                target.heading = shipHelpers.headingOrCog(target.heading, target.cog);
                                const icon = shipHelpers.shipIcon(target.navstat, 'ts', target.mmsi, null, target.sog);
                                if (icon) {
                                    target.icon = (
                                        L.icon({
                                            iconUrl: require(`@/assets/img/ships/${icon}`),
                                            iconSize: [11, 30], // size of the icon
                                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                                        })
                                    );
                                }
                                target.tooltip = target.name;
                                target.tooltipOptions = shipHelpers.shipTooltipOptions(o, L);
                                target.popup = shipHelpers.shipPopup(target);
                                return target;
                            }
                        });
                    });
                }
            },
            switchPanel(title) {
                this.activePanel = title;
                this.eventMarker = {};
                if (title === 'Close') {
                    this.initPanel(null);
                } else if (title === 'Maranics') {
                    this.updateChecklists();
                    if (!this.checklistInterval) {
                        this.checklistInterval = setInterval(() => {
                            this.updateChecklists();
                        }, 2*60*1000);
                    }
                } else if (title === 'Norppa') {
                    this.updateNorppaData();
                    if (!this.checklistInterval) {
                        this.checklistInterval = setInterval(() => {
                            this.updateNorppaData();
                        }, 2*60*1000);
                    }
                } else if (title === 'Logbook' && this.ship.logDb && this.user.user.logbooks && this.user.user.logbooks.includes(this.ship.logDb)) {
                    this.fetchRecords({
                        logDb: this.ship.logDb,
                        start: moment.utc().subtract(1, 'd').format(),
                        end: moment.utc().format()
                    });
                    if (!this.logbookInterval) {
                        this.logbookInterval = setInterval(() => {
                            this.fetchRecords({
                                logDb: this.ship.logDb,
                                start: moment.utc().subtract(1, 'd').format(),
                                end: moment.utc().format()
                            });
                        }, 2*60*1000);
                    }
                }
            },
            updateChecklists() {
                this.fetchChecklists(this.ship.id).then((data) => {
                    this.checklists = data;
                });
            },
            updateNorppaData() {
                this.fetchNorppaData(this.ship.mmsi).then((data) => {
                    this.schedule = data;
                });
            },
            formatTime(value) {
                if (value) {
                    return `${moment.utc(value).format('D.M HH:mm')}`;
                }
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat, true);
            },
            timeAgo(time) {
                return tools.timeAgo(time);
            },
            timeAgoColor(time) {
                return tools.timeAgoColor(time);
            },
            round(value, decimals) {
                if ((value >= 0 && value < 0.0001) || value > 100000000) {
                    return 0;
                } else if ((value && !isNaN(value)) || value === 0) {
                    return Number.parseFloat(value).toFixed(decimals);
                } else {
                    return 'N/A';
                }
            },
            unit(value, unit) {
                 if (value && !isNaN(value)) {
                    return unit;
                } else {
                    return '';
                }
            },
            sogColor(sog) {
                if (Number.parseFloat(sog) > 2) {
                    return {
                        'border-bottom': '15px solid rgb(68, 108, 179)'
                    };
                } else {
                    return {
                        'border-bottom': '15px solid #abb7b7'
                    };
                }
            },
            getStreamUrl(index, shipId) {
                if (this.streams[index].vIconColor === 'success') { // If stream already on, close
                    this.endStream(index);
                } else {
                    this.fetchStreamUrl({
                        shipId,
                        camId: this.streams[index].id
                    }).then((data) => {
                        this.streams[index].url = data.url;
                        this.initPlayer(index);
                    });
                }
            },
            initPlayer(index) {
                let cam = this.streams[index];
                // eslint-disable-next-line
                this.sldpPlayers[cam.id] = SLDP.init({
                    container: `sldp_player_wrapper${cam.id}`,
                    stream_url: cam.url,
                    height: 'parent',
                    width: 'parent',
                    autoplay: true,
                    muted: true,
                });
                if (cam.timeout && cam.timeout !== 0) {
                    this.streams[index].counter = cam.timeout;
                    this.streams[index].interval = setInterval(() => {
                        this.streams[index].counter--;
                        let mins = Math.floor(this.streams[index].counter / 60);
                        let secs = this.streams[index].counter - mins * 60;
                        secs = secs < 10 ? `0${secs}` : secs;
                        this.$set(this.streams[index], 'button', `End stream ${mins}:${secs}`);
                    }, 1000);
                    setTimeout(() => {
                        this.endStream(index);
                        if (this.sldpPlayers && this.sldpPlayers[cam.id]) {
                            this.sldpPlayers[cam.id].destroy();
                        }
                        clearInterval(this.streams[index].interval);
                        this.$set(this.streams[index], 'button', null);
                    }, cam.timeout * 1000);
                }
            },
            endStream(index) {
                if (this.streams && this.streams[index]) {
                    let cam = this.streams[index]
                    if (this.sldpPlayers && this.sldpPlayers[cam.id]) {
                        this.sldpPlayers[cam.id].destroy();
                        clearInterval(this.streams[index].interval);
                        this.$set(this.streams[index], 'button', null);
                    }
                }
            },
            cameraCommand(cam, shipId, control) {
                if (control.type === 'image') {
                    this.sendCameraCommandImage({
                        shipId: shipId,
                        camId: cam.id,
                        controlId: control.id
                    }).then((response) => {
                        if (response && control.type === 'image') {
                            let filename = control.filename ? control.filename : 'cam-still.jpeg';
                            tools.saveFile(response.data, response.headers, moment.utc().format('YMMDDHHmm') + '-' + filename);
                        }
                    });
                } else {
                    this.sendCameraCommand({
                        shipId: shipId,
                        camId: cam.id,
                        controlId: control.id
                    });
                }
            },
            saveHidden() {
                if (this.userLevel < 2) {
                    return;
                }
                let postData = {
                    data: this.checklistItems,
                    shipId: this.ship.id
                };
                this.saveChecklistItems(postData).then((data) => {
                    this.checklists = data;
                    this.dialog = false;
                });
            },
            getItemTitles() {
                if (!this.checklistTitles) {
                    this.fetchChecklistTitles().then((data) => {
                        this.checklistTitles = data.titles;
                        this.checklistItems = data.hidden;
                    })
                }
            },
            showEventMarker(item) {
                if (item.latitude, item.longitude) {
                    this.eventMarker.title = item.itemTitle;
                    this.eventMarker.latLng = L.latLng(item.latitude, item.longitude);
                    this.center = L.latLng(item.latitude, item.longitude);
                } else if (item.content && item.content.fields && item.content.fields[2] && item.content.fields[3]) {
                    // convert to decimal format
                    let lat = item.content.fields[2].value ? item.content.fields[2].value : item.content.fields[2].autoValue ? item.content.fields[2].autoValue : null;
                    let lon = item.content.fields[3].value ? item.content.fields[3].value : item.content.fields[3].autoValue ? item.content.fields[3].autoValue : null;
                    if (lat && lon) {
                        lat = tools.dmmToDecimal(lat);
                        lon = tools.dmmToDecimal(lon);
                        this.eventMarker.title = item.summary;
                        this.eventMarker.latLng = L.latLng(lat, lon);
                        this.center = L.latLng(lat, lon);
                    }
                }
            },
            compass(dir) {
                return tools.getCompassPoint(dir);
            },
            updateZoom(e) {
                this.zoom = e;
            },
            toggle(name) {
                if (name === 'showRoutes') {
                    this.showRoutes = !this.showRoutes;
                    localStorage.setItem(name, this.showRoutes ? 1 : 0);
                } else if (name === 'showCircles') {
                    this.showCircles = !this.showCircles;
                    localStorage.setItem(name, this.showCircles ? 1 : 0);
                }
            },
            inPast(item) {
                if (item.Status !== 'Pres' && item.Status !== 'Next') {
                    return true;
                } else {
                    return false;
                }
            },
            showSubtitle(item, i) {
                if (typeof item.TIMEZONE === 'undefined' || item.TIMEZONE === null) {
                    item.TIMEZONE = 0;
                }
                if (i === this.nextIndex) {
                    if (item.rtaEta && moment.utc(item.rtaEta).isValid() ) {
                        return 'RTA Berth ' + tools.formatDate(item.rtaEta, item.TIMEZONE);
                    } else if (item.etaPbpUser && moment.utc(item.etaPbpUser).isValid()) {
                        return 'ETA PBP ' + tools.formatDate(item.etaPbpUser, item.TIMEZONE);
                    } else if (item.s5Eta && moment.utc(item.s5Eta).isValid()) {
                        return 'ETA PBP ' + tools.formatDate(item.s5Eta, item.TIMEZONE);
                    } else if (item.AIS_ETA && moment.utc(item.AIS_ETA).isValid()) {
                        return 'ETA PBP ' + tools.formatDate(item.AIS_ETA, item.TIMEZONE);
                    } else {
                        return 'ETA is not available';
                    }
                } else if (i < this.nextIndex || this.nextIndex === -1) {
                    if (item.s5Atd && moment.utc(item.s5Atd).isValid()) {
                        return 'ATD ' + tools.formatDate(item.s5Atd, item.TIMEZONE);
                    } else if (item.ATD && moment.utc(item.ATD).isValid()) {
                        return 'ATD ' + tools.formatDate(item.ATD, item.TIMEZONE);
                    } else if (item.s5Ata && moment.utc(item.s5Ata).isValid()) {
                        return 'ATA ' + tools.formatDate(item.s5Ata, item.TIMEZONE);
                    } else if (item.ATA && moment.utc(item.ATA).isValid()) {
                        return 'ATA ' + tools.formatDate(item.ATA, item.TIMEZONE);
                    } else {
                        return 'ATA/ATD is not available';
                    }
                } else if (i > this.nextIndex) {
                    if (item.rtaEta && moment.utc(item.rtaEta).isValid() ) {
                        return 'RTA Berth ' + tools.formatDate(item.rtaEta, item.TIMEZONE);
                    } else if (item.AIS_ETA && moment.utc(item.AIS_ETA).isValid()) {
                        return 'Planned ' + tools.formatDate(item.AIS_ETA, item.TIMEZONE);
                    } else {
                        return 'Planned ETA is not available';
                    }
                }
            },
            statusColor(item) {
                switch (item.Status) {
                    case 'Pres':
                        return '#00B06B';
                    case 'Next':
                        return '#A3004B';
                    default:
                        return '#ccc';
                }
            },
            operationColor(item) {
                if (this.inPast(item)) {
                    return '#ccc';
                } else {
                    switch (item.Operation) {
                        case 'L':
                            return '#00B06B';
                        case 'D':
                            return '#A3004B';
                        default:
                            return '#ccc';
                    }
                }
            },
            fromNow(value) {
                return moment.utc(value).fromNow();
            },
        },
        provide: {
            grid: [Aggregate, RowDD]
        }
    };
</script>

<style scoped>
    .slider {
        position: absolute;
        z-index: 1000;
        width: 100%;
        left: 0;
        bottom: 15px;
    }

    .arrow_sog {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        display: inline-block;
    }
    .chart{
        width: 100%;
        height: 250px;
        margin-top:10px;
    }
</style>

<style>
    .leaflet-control-velocity {
        position: absolute;
        bottom: 0;
        margin-bottom: 0 !important;
        background-color: rgba(255, 255, 255, 0.7);
        -webkit-box-shadow: 0 0 5px #bbb;
        box-shadow: 0 0 5px #bbb;
        padding: 0 5px;
        margin: 0;
        color: #333;
        font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
        left: 270px;
        white-space: nowrap;
    }

    .v-navigation-drawer {
        z-index: 10000;
    }

    .saturday {
        color: #f39c12 !important;
    }

    .sunday {
        color: red !important;
    }

    .xlarge {
        font-size: 24px;
    }

    .large {
        font-size: 18px;
    }

    .schedule-icon {
        height: 30px;
        width: 30px;
        position: absolute !important;
    }

    .v-icon.loading {
        background-image: url(../assets/img/loading.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 5px;
    }

    .v-icon.loading::before {
        visibility: hidden;
        content: "";
    }

    .v-icon.loading-past {
        background-image: url(../assets/img/loading_past.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 5px;
    }

    .v-icon.loading-past::before {
        visibility: hidden;
        content: "";
    }

    .v-icon.discharging {
        background-image: url(../assets/img/discharge.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 5px;
    }

    .v-icon.discharging::before {
        visibility: hidden;
        content: "";
    }

    .v-icon.discharging-past {
        background-image: url(../assets/img/discharge_past.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 5px;
    }

    .v-icon.discharging-past::before {
        visibility: hidden;
        content: "";
    }

    .v-icon.anchor {
        background-image: url(../assets/img/anchoring.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 5px;
    }

    .v-icon.anchor::before {
        visibility: hidden;
        content: "";
    }

    .v-icon.norppa {
        background-image: url(../assets/img/norppa.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -7px;
        margin-left: -3px;
    }

    .v-icon.norppa::before {
        visibility: hidden;
        content: "";
    }

    .v-expansion-panel-content__wrap {
        padding: 0 12px 8px !important;
    }

    .mdi-chevron-down {
        display: none !important;
    }

    .v-expansion-panel--active .mdi-chevron-down {
        display: block !important;
    }

    .v-expansion-panel--active .hide-active {
        display: none !important;
    }

    .v-alert__content {
        color: #333333;
    }

    .v-alert {
        font-size: 14px;
        border: solid 2px !important;
    }

    .show-details {
        cursor: pointer;
        font-weight: 700;
        margin-left: 10px;
        color: #003C96;
        font-size: 12px;
        margin-top: 8px;
    }
</style>